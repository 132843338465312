:host {
  --accent-color: var(--color-primary);
  --accent-hover-color: var(--color-dark-primary-hover);
  --text-color: #fff;

  color: var(--text-color);
}

:is(button, a) {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  appearance: none;
  display: inline-flex;
  gap: var(--spacing-2);
  align-items: center;
  justify-content: center;
  box-shadow: none;
  line-height: 1.5;
  position: relative;
  vertical-align: top;
  font-family: inherit;
  font-weight: 600;
  white-space: normal;
  word-break: break-word;
  cursor: pointer;
  padding-block: var(--spacing-2);
  padding-inline: var(--spacing-4);
  text-align: center;
  background-color: var(--accent-color);
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 12px;
  color: currentColor;
  font-size: 1rem;
}

:is(button, a):hover {
  background-color: var(--accent-hover-color);
}

::slotted(sl-icon) {
  text-shadow: 0px 0px 2px currentColor; /* TODO: does this actually do anything now? */
  font-size: 115%;
  height: 1.3em;
  width: 1.3em;
  padding-inline: 0;
}

[name="prefix"]::slotted(sl-icon) {
  margin-inline-start: -0.2em;
}

[name="suffix"]::slotted(sl-icon) {
  margin-inline-end: -0.2em;
}

:is(button, a)[pg-size="small"] {
  border-radius: 3px;
  font-size: .75rem;
  padding-block: calc(var(--spacing-1) * 1.5);
  padding-inline: var(--spacing-3);
}

:is(button, a)[pg-size="large"] {
  font-size: 1.5em;
  padding-block: var(--spacing-2);
  padding-inline: var(--spacing-5);
}